import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';

/**
 * Track a generic event
 * @param {string} eventName - Firebase event name
 * @param {object} params - Additional parameters
 */
export const trackEvent = (eventName, params = {}) => {
    if (analytics && typeof logEvent === 'function') {
        logEvent(analytics, eventName, params);
    } else {
        console.warn("Firebase Analytics is not initialized");
    }
};

/**
 * Track route view
 * @param {string} path - Current pathname
 */
export const trackPageView = (path) => {
    trackEvent('page_view', {
        page_path: path,
        page_title: document.title,
    });
};

/**
 * Track button clicks
 * @param {string} buttonName - e.g. 'call_button', 'message_button'
 */
export const trackButtonClick = (buttonName, params) => {
    if (analytics) {
        analytics.logEvent(buttonName, params);
    }
};

/**
 * Track category and subcategory selection
 * @param {string} category - Category name
 * @param {string} subcategory - Subcategory name
 */
export const trackCategorySelection = (category, subcategory) => {
    trackEvent('category_selection', {
        category_name: category,
        subcategory_name: subcategory,
    });
};