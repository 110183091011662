import React, { useEffect, useState } from "react";
import { getNotifications } from "../../../api/get/getNotifications";

import NewItem from "../items/NewItem";

const Articles = ({ service }) => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        async function fetchNotifications() {
            try {
                const serviceType = service || "articles";
                const data = await getNotifications({ service: serviceType }, 1, 10);
                setNotifications(data.items || []);
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        }

        fetchNotifications();
    }, []);

    return (
        <>
            {notifications.length > 0 ? (
                <div className="result_grid">
                    {notifications.map((notification) => (
                        <NewItem
                            key={notification.id}
                            id={notification.id}
                            name={notification.title}
                            text={notification.text}
                            date={notification.created_at}
                            file={notification.file}
                        />
                    ))}
                </div>
            ) : (
                <p>Скоро появится полезная информация!</p>
            )}
        </>
    );
};

export default Articles;