import React from "react";
import '../style/AboutLeftNav.css';
import { t } from "i18next";

const AboutLeftNav = ({ activeSection, handleSectionChange }) => {
    return (
        <div>
            <aside className="sidebar">
                <nav>
                    <ul>
                        <li className="nav-title">{t("navigation") || "Навигация"}</li>
                        <li>
                            <a
                                onClick={() => handleSectionChange("main")}
                                className={`nav-link ${activeSection === "main" ? "active" : ""}`}
                                href="#main"
                            >
                                {activeSection === "main" && <div className="active_tab_left"></div>}
                                {t("aboutUs") || "О компании"} <span className="arrow">›</span>
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={() => handleSectionChange("contacts")}
                                className={`nav-link ${activeSection === "contacts" ? "active" : ""}`}
                                href="#contacts"
                            >
                                {activeSection === "contacts" && <div className="active_tab_left"></div>}
                                {t("contacts") || "Контакты"} <span className="arrow">›</span>
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={() => handleSectionChange("policy")}
                                className={`nav-link ${activeSection === "policy" ? "active" : ""}`}
                                href="#policy"
                            >
                                {activeSection === "policy" && <div className="active_tab_left"></div>}
                                {t("privacyPolicy") || "Политика конфиденциальности"} <span className="arrow">›</span>
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={() => handleSectionChange("terms")}
                                className={`nav-link ${activeSection === "terms" ? "active" : ""}`}
                                href="#terms"
                            >
                                {activeSection === "terms" && <div className="active_tab_left"></div>}
                                {t("termsOfUse") || "Условия использования"} <span className="arrow">›</span>
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={() => handleSectionChange("faq")}
                                className={`nav-link ${activeSection === "faq" ? "active" : ""}`}
                                href="#faq"
                                style={{ borderBottom: 0 }}
                            >
                                {activeSection === "faq" && <div className="active_tab_left"></div>}
                                {t("howToBuyAndSell") || "Как продавать и покупать?"} <span className="arrow">›</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </aside>
        </div>
    );
};

export default AboutLeftNav;