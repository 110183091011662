import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../main/components/Header';
import MainImageSlider from '../components/MainImageSlider';
import Description from '../components/Description';
import UserInfo from '../components/UserInfo';
import AuthorAds from '../components/AuthorAds';
import SimilarAds from '../components/SimilarAds';
import Path from '../../path/ui/Path';
import MobileBottomBar from '../../main/components/MobileBottomBar';
import Fooder from '../../main/components/Fooder';

import getAnnouncementById from '../../../api/getAnnouncementById';

import '../style/main.css';

import Loading from '../../../common/components/Loading';

import eyeIcon from '../../../assets/icons/eye.svg';

import { trackPageView } from '../../../utils/AnalyticsUtils';

const Main = () => {
    const { id } = useParams();
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [bottomBarHidden, setBottomBarHidden] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchItem = async () => {
            try {
                const response = await getAnnouncementById(id);
                setItem(response.announcement);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchItem();
    }, [id]);

    useEffect(() => {
        if (item) {
            trackPageView('announcement_view', {
                announcement_id: item.id,
                announcement_title: item.title,
                views: item.views || 0,
            });
        }
    }, [item]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!item) {
        return <div>Item not found</div>;
    }

    const handleBottomBarToggle = (isHidden) => {
        setBottomBarHidden(isHidden);
    };

    return (
        <div className={`main_container ${isMobile ? 'mobile' : 'desktop'}`}>
            <Header onToggleMenu={handleBottomBarToggle} />
            <Path currentPath={item.title || 'Unknown Item'} />
            <div className="announcement_content">
                <div className="main_content">
                    <MainImageSlider
                        id={item.id}
                        images={Array.isArray(item.image_urls) && item.image_urls.length > 0 ? item.image_urls : []}
                        description={item.description || 'Нет описания'}
                        views={item.views || 0}
                        videoUrl={item.video_url || null}
                        isMobile={isMobile}
                    />
                    <div className="main_details">
                        <Description
                            id={item.id}
                            title={item.title}
                            date={item.created_at}
                            price={`${item.price} ${item.currency}`}
                            description={item.description}
                            phone={Array.isArray(item.contact_numbers) && item.contact_numbers.length > 0
                                ? item.contact_numbers[0].phone_number
                                : 'No phone available'}
                            rating={item.rating || ''}
                            reviews={item.reviews || ''}
                            user_id={item.seller.id}
                        />
                        {isMobile &&
                            <div className="ad_description">
                                <h3>Описание</h3>
                                <p>{item.description || "Нет описания"}</p>
                                <div className="ad_views">
                                    <div className='ad_views'>
                                        <img src={eyeIcon} alt='eye' />
                                        <span>Просмотров: {item.views || 0}</span>
                                    </div>
                                    <span>ID: {item.id}</span>
                                </div>
                            </div>
                        }
                        <UserInfo
                            user={item.seller}
                            locations={item.location}
                        />
                    </div>
                </div>
                {Array.isArray(item.additional_announcements) && item.additional_announcements.length > 0 && (
                    <AuthorAds
                        ads={item.additional_announcements}
                        user={item.seller}
                    />
                )}
                {Array.isArray(item.similar_announcements) && item.similar_announcements.length > 0 && (
                    <SimilarAds ads={item.similar_announcements} />
                )}
                {!bottomBarHidden && <MobileBottomBar activeTabName={'home'} />}
                <Fooder />
            </div>
        </div>
    );
};

export default Main;