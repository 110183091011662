import React, { useState, useEffect } from "react";
import Header from "../../main/components/Header";
import About from "../components/About";
import Fooder from "../../main/components/Fooder";
import Path from "../../path/ui/Path";
import "../style/Main.css";
import MobileBottomBar from "../../main/components/MobileBottomBar";
import { t } from "i18next";

function Main() {
    const [activeSection, setActiveSection] = useState("main");

    useEffect(() => {
        const updateSectionFromHash = () => {
            const hash = window.location.hash.replace("#", "");
            if (hash) {
                setActiveSection(hash);
            }
        };

        updateSectionFromHash();

        window.addEventListener("hashchange", updateSectionFromHash);

        return () => {
            window.removeEventListener("hashchange", updateSectionFromHash);
        };
    }, []);

    const handleSectionChange = (section) => {
        setActiveSection(section);
        window.location.hash = `#${section}`;
    };

    const sectionTitles = {
        main: t("about.company"),
        contacts: t("about.contacts"),
        policy: t("about.policy"),
        terms: t("about.terms"),
        faq: t("about.faq"),
    };

    return (
        <div className="def_background">
            <Header />
            <Path currentPath={sectionTitles[activeSection]} />
            <div className="main_about_content">
                <About activeSection={activeSection} handleSectionChange={handleSectionChange} />
            </div>
            <div className="fooder">
                <Fooder />
            </div>
            <MobileBottomBar />
        </div>
    );
}

export default Main;