import React from "react";
import { t } from "i18next";

export function NotifFilterTabButtons({ tab, setTab, counts }) {
    return (
        <div className="filters">
            <button
                className={tab === "agroland" ? "active" : ""}
                onClick={() => setTab("agroland")}
            >
                {t('appTitle')} <span className="chat_filter_count">{counts.active || 0}</span>
            </button>
            <button
                className={tab === "support" ? "active" : ""}
                onClick={() => setTab("support")}
            >
                {t('support')} <span className="chat_filter_count">{counts.pending || 0}</span>
            </button>
            <button
                className={tab === "promotions" ? "active" : ""}
                onClick={() => setTab("promotions")}
            >
                {t('promotions')} <span className="chat_filter_count">{counts.inactive || 0}</span>
            </button>
        </div>
    );
}