import Main from '../app/main/ui/Main';
import Category from '../app/category/ui/Main';
import Announcement from '../app/announcement/ui/Main';
import Sign from '../app/sign/ui/Main';
import SmsCode from '../app/sign/sms/SmsCode';
import Register from '../app/sign/in/Register';
import Profile from '../app/profile/ui/Main';
import AdForm from '../app/adform/ui/Main';
import AboutUs from '../app/aboutus/ui/Main';
import Review from '../app/review/ui/Review';
import Promote from '../app/promote/ui/Main';
import Company from '../app/company/ui/Main';
import Edit from '../app/edit/ui/Main';
import Reviews from '../app/reviews/ui/Main';
import News from '../app/news/ui/Main';
import Adshow from '../app/adshow/ui/Main';
import Notifications from '../app/notifications/ui/Main';
import NotFound from '../app/notfound/ui/NotFound.js';

const routes = [
    { path: '/', element: <Main /> },
    { path: '/category/:category_name/:subcategory_name/:category_id/:subcategory_id', element: <Category /> },
    { path: '/announcement/:id', element: <Announcement /> },
    { path: '/sign', element: <Sign /> },
    { path: '/sms-code', element: <SmsCode /> },
    { path: '/register', element: <Register /> },
    { path: '/profile', element: <Profile /> },
    { path: '/adform', element: <AdForm /> },
    { path: '/preview', element: <Review /> },
    { path: '/aboutus', element: <AboutUs /> },
    { path: '/promote', element: <Promote /> },
    { path: '/company', element: <Company /> },
    { path: '/edit', element: <Edit /> },
    { path: '/reviews', element: <Reviews /> },
    { path: '/news/:id', element: <News /> },
    { path: '/adshow', element: <Adshow /> },
    { path: '/notifications', element: <Notifications /> },
    { path: '*', element: <NotFound /> },
];

export default routes;