import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/Login.css';

import logo from '../../../app_icon.svg';
import Modal from '../../../common/components/MessageModal';
import { loginUser } from '../../../api/auth';
import fetchUserCountry from '../../../api/getUserCountry';

import user from '../../../assets/icons/user.svg';
import briefcase from '../../../assets/icons/briefcase.svg';
import FloatingLabelInput from '../../../common/components/FloatingLabelInput';
import { t } from 'i18next';

import { trackPageView } from '../../../utils/AnalyticsUtils'; // Import trackPageView function

const countryMasks = {
    Kazakhstan: '+7 (999) 999 99 99',
    Russia: '+7 (999) 999 99 99',
    China: '+86 (999) 9999 9999',
    default: '+7 (999) 999 99 99',
};

const Login = () => {
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [mask, setMask] = useState(countryMasks.default);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        trackPageView('login');

        const fetchCountry = async () => {
            try {
                const country = await fetchUserCountry();
                setMask(countryMasks[country] || countryMasks.default);
            } catch (error) {
                console.error('Error fetching user country:', error);
            }
        };

        fetchCountry();

        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            navigate('/profile#walletTopUp');
        }
    }, [navigate]);

    const handleBackClick = () => {
        navigate('/');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!phone.trim()) {
            setError(t('enter_phone'));
            setShowModal(true);
            return;
        }
    
        const sanitizedPhoneNumber = phone.replace(/\D/g, '');
        const formattedPhoneNumber = sanitizedPhoneNumber.startsWith('7') 
            ? `+${sanitizedPhoneNumber}` 
            : `+7${sanitizedPhoneNumber}`;
    
        try {
            const data = await loginUser(formattedPhoneNumber);
            navigate('/sms-code', { 
                state: { 
                    taskId: data.links[0].href.split('/').pop(), 
                    phoneNumber: formattedPhoneNumber, 
                    typeAuth: 'login' 
                } 
            });
        } catch (error) {
            setError(error.message || 'Ошибка сети. Попробуйте позже.');
            setShowModal(true);
        }
    };    

    const handleCloseModal = () => {
        setShowModal(false);
        setError('');
    };

    return (
        <div className="sign_page">
            <div className="left_section"></div>

            <div className="right_section">
                <div className="right_section_header">
                    <button onClick={handleBackClick} className="back_button">
                        <i className="back_icon fa-solid fa-chevron-left"></i>
                        {isMobile ? t('signIn') : t('back')}
                    </button>

                    {!isMobile &&
                        <div className="logo_container">
                            <img src={logo} alt="AgroFerma Logo" className="logo" />
                        </div>
                    }
                </div>
                <form onSubmit={handleSubmit} className="sign_form">
                    {isMobile &&
                        <div className="logo_container">
                            <img src={logo} alt="Logo" className="form_logo" />
                        </div>
                    }
                    {!isMobile &&
                        <h2 className="title">{t('signIn')}</h2>
                    }
                    <FloatingLabelInput
                        label={t('enter_phone')}
                        mask={mask}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <button type="submit" className="submit_button">
                        {t('next')}
                    </button>
                </form>

                <div className="registration_section_buttons">
                    <button
                        onClick={() => navigate('/register', { state: { userType: 'individual' } })}
                        className="register_button"
                    >
                        {t('forIndividuals')} <img className="green_icon" src={user} alt="User Icon" />
                    </button>
                    <button
                        onClick={() => navigate('/register', { state: { userType: 'business' } })}
                        className="register_button"
                    >
                        {t('forBusinesses')} <img src={briefcase} alt="Briefcase Icon" />
                    </button>
                </div>
            </div>

            <Modal show={showModal} message={error} onClose={handleCloseModal} />
        </div>
    );
};

export default Login;