import React, { useState } from 'react';

import Header from '../../main/components/Header';
import Path from '../../path/ui/Path';
import MobileBottomBar from '../../main/components/MobileBottomBar';
import Fooder from '../../main/components/Fooder';
import { t } from 'i18next';
import { NotifFilterTabButtons } from '../components/NotifFilterTabButtons';

import Icon from '../../../common/components/Icon';

import '../style/Notification.css';
import Articles from '../../aboutus/components/Articles';

const Main = () => {
    const [tab, setTab] = useState('agroland');
    const counts = {
        active: 5,
        pending: 3,
        inactive: 2,
    };
    return (
        <div className="notifications_main_container">
            <Header />
            <Path currentPath={t('notifications')} />
            <div className='notification_container'>
                <div className="content">
                    <Icon src="backicon" className={"back_icon_mobile"} />
                    <h2 className="menu_right_section_header_text">{t('announcements')}</h2>
                    <NotifFilterTabButtons tab={tab} setTab={setTab} counts={counts} />
                </div>
                <Articles service={'promotions'} />
            </div>
            <div className="announcement_content">
                <MobileBottomBar />
                <Fooder />
            </div>
        </div>
    );
};

export default Main;